import React from "react"
import { Container, Button } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import SEO from "../components/seo"
import Layout from "../components/Layout/Layout"

const Canvas = props => (
  <Layout>
    <SEO title="Thank you!" />
    <Container
      style={{
        maxWidth: "900px",
        margin: "auto",
        textAlign: "center",
        padding: "100px 10%",
        minHeight: "100%",
        lineHeight: "1.5em",
        color: "#315550",
      }}
    >
      <Img
        fluid={props.data.thanksImage.image.fluid}
        alt="thank you!"
        style={{
          maxWidth: "90%",
          margin: "auto",
          border: "solid #DDD 1px",
          boxShadow: "0 0.375rem 1.5rem 0 rgba(140, 152, 164, 0.5)",
          borderRadius: "20px",
        }}
      />
      <h3 style={{ marginTop: "20px" }}>Thank you for your purchase!</h3>
      <p>We look forward to supporting your load shifting goals.</p>{" "}
      <p>
        If you already have an account, you may continue to use it as usual. If
        not, we will email you your login details within 48 hours.
      </p>
      <p>
        If you are not sure if we have your email address or you want to check
        the status of your account, please reach out at{" "}
        <a href="mailto:support@gridfabric.zendesk.com">
          support@gridfabric.zendesk.com
        </a>
        .
      </p>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Button href="/" size="lg" className="btn-gridfabric">
          Go Home
        </Button>
      </div>
    </Container>
  </Layout>
)

export default Canvas

export const canvasCloudPageQuery = graphql`
  query {
    thanksImage: contentfulWebsiteImages(imageId: { eq: "thankYouSun" }) {
      image {
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`
